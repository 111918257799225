import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { usePayTranslation } from "../../../01_technical/translations"
import { CBLogo } from "../../../assets/logos/CB"
import { LockLogo } from "../../../assets/logos/Lock"
import { MastercardLogo } from "../../../assets/logos/Mastercard"
import { VisaLogo } from "../../../assets/logos/Visa"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 26rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
  padding: 1.5rem;

  @media (max-width: 900px) {
    width: 100%;
    border-radius: unset;
  }
`

const CardAcceptedLogos = styled.div`
  & > svg {
    margin-right: 0.5rem;
  }
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$600};
  margin-left: 0.2rem;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.7rem 0 0.2rem 0;
`

const Content = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const Card3DSInfos = () => {
  const { t } = usePayTranslation()

  return (
    <Container>
      <CardAcceptedLogos>
        <MastercardLogo />
        <VisaLogo />
        <CBLogo />
      </CardAcceptedLogos>
      <TitleContainer>
        <LockLogo />
        <Title $variant="body-3-semibold">{t("paymentPage.card3ds.title")}</Title>
      </TitleContainer>
      <Content $variant="body-4-regular">{t("paymentPage.card3ds.content")}</Content>
    </Container>
  )
}

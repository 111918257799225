import { useCallback, useState } from "react"

export const useSignedUrlFileDownload = () => {
  const [downloadState, setDownloadState] = useState<"idle" | "loading" | Error>("idle")

  const triggerFileDownload = useCallback(
    (route: string) => {
      setDownloadState("loading")

      fetch(route)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(response.statusText)
          }

          const signedUrl = response.url
          window.open(signedUrl, "_blank")
        })
        .catch((err) => {
          setDownloadState(err)
        })
    },
    [setDownloadState],
  )

  return [
    triggerFileDownload,
    {
      loading: downloadState === "loading",
      error: downloadState instanceof Error ? downloadState : undefined,
    },
  ] as const
}

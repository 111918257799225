import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { PaymentStatus } from "../../../business/enum/PaymentStatus"
import { PaymentType } from "../../../business/enum/PaymentType"
import { CustomerAddress } from "../../business/address.type"

export const useGetPaymentCheckout = (variables: { paymentCheckoutId?: string }) => {
  const { data, error, loading } = useHeroQuery<getPaymentCheckoutConfigurationResponse>({
    gqlQuerySchema: GET_PAYMENT_CHECKOUT_CONFIGURATION,
    variables: {
      paymentCheckoutId: variables.paymentCheckoutId,
    },
    skip: !variables.paymentCheckoutId,
  })

  return {
    data,
    error,
    loading,
  }
}

type getPaymentCheckoutConfigurationResponse = {
  amount: number
  merchantTradingName: string
  merchantLogo?: string
  customerInfos: {
    name: string
    siret?: string
    isIndividual: boolean
    billingAddress: CustomerAddress
    shippingAddress?: CustomerAddress
  }
  availablePaymentTypesWithInstallments: {
    feeConfigurationId: string
    type: PaymentType
    installmentCount: number
    daysBeforeDueDate: number
    installments: {
      amount: number
      dueDate: string
      position: number
    }[]
  }[]
  isInitiated: boolean
  paymentStatus: PaymentStatus | null
  selectedFeeConfiguration: {
    feeConfigurationId: string
    type: PaymentType
    installmentCount: number
    daysBeforeDueDate: number
    installments: {
      amount: number
      dueDate: string
      position: number
    }[]
  } | null
}

const GET_PAYMENT_CHECKOUT_CONFIGURATION = gql`
  query customerGetPaymentCheckoutConfiguration($paymentCheckoutId: String!) {
    customerGetPaymentCheckoutConfiguration(paymentCheckoutId: $paymentCheckoutId) {
      ... on CustomerGetPaymentCheckoutOutput {
        amount
        merchantTradingName
        merchantLogo
        customerInfos {
          name
          siret
          isIndividual
          billingAddress {
            line1
            line2
            city
            zipCode
            country
          }
          shippingAddress {
            line1
            line2
            city
            zipCode
            country
          }
        }
        availablePaymentTypesWithInstallments {
          feeConfigurationId
          type
          installmentCount
          daysBeforeDueDate
          installments {
            amount
            dueDate
            position
          }
        }
        isInitiated
        paymentStatus
        selectedFeeConfiguration {
          feeConfigurationId
          type
          installmentCount
          daysBeforeDueDate
          installments {
            amount
            dueDate
            position
          }
        }
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

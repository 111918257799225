import React from "react"
import styled from "styled-components"
import { usePayTranslation } from "../../01_technical/translations"

const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const NotFoundText = styled.p`
  text-align: center;
`

const NotFoundComponent: React.FC = () => {
  const { t } = usePayTranslation()

  return (
    <NotFoundContainer>
      <NotFoundText>{t("notFound")}</NotFoundText>
    </NotFoundContainer>
  )
}

export default NotFoundComponent

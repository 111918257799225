export const VisaLogo = () => {
  return (
    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="36.5" height="24.5" rx="4.25" fill="#F8F8F8" stroke="#E1E1E1" strokeWidth="0.5" />
      <path d="M17.5322 16.5174H15.75L16.8647 9.625H18.6467L17.5322 16.5174Z" fill="#00579F" />
      <path
        d="M23.9944 9.79344C23.6429 9.65398 23.0854 9.5 22.396 9.5C20.636 9.5 19.3967 10.4385 19.3891 11.7802C19.3745 12.7702 20.2764 13.32 20.951 13.6501C21.6405 13.9874 21.8749 14.2075 21.8749 14.5082C21.8679 14.9699 21.3177 15.1828 20.8046 15.1828C20.0931 15.1828 19.7119 15.073 19.1325 14.8162L18.8978 14.706L18.6484 16.2532C19.0664 16.4436 19.8365 16.6126 20.636 16.62C22.506 16.62 23.7234 15.6961 23.7378 14.2662C23.7449 13.4816 23.2687 12.8804 22.2419 12.3891C21.6186 12.0738 21.2368 11.8611 21.2368 11.5385C21.2441 11.2451 21.5597 10.9447 22.2633 10.9447C22.8427 10.93 23.2684 11.0692 23.5909 11.2086L23.752 11.2818L23.9944 9.79344Z"
        fill="#00579F"
      />
      <path
        d="M26.3628 14.0757C26.5095 13.6797 27.0743 12.1472 27.0743 12.1472C27.0669 12.1619 27.2207 11.744 27.3087 11.4874L27.4333 12.0812C27.4333 12.0812 27.7708 13.7311 27.8441 14.0757C27.5655 14.0757 26.7148 14.0757 26.3628 14.0757ZM28.5626 9.625H27.1841C26.759 9.625 26.436 9.74955 26.2526 10.1969L23.6055 16.5173H25.4754C25.4754 16.5173 25.7833 15.6666 25.8495 15.4834C26.0546 15.4834 27.8737 15.4834 28.1376 15.4834C28.1888 15.7254 28.3502 16.5173 28.3502 16.5173H30.0003L28.5626 9.625Z"
        fill="#00579F"
      />
      <path
        d="M14.2633 9.625L12.5179 14.3249L12.3272 13.3717C12.0046 12.2719 10.9926 11.0769 9.86328 10.4827L11.4619 16.5101H13.3465L16.1477 9.625H14.2633Z"
        fill="#00579F"
      />
      <path
        d="M10.8966 9.625H8.02933L8 9.76426C10.2367 10.3362 11.718 11.7149 12.3266 13.372L11.7033 10.2044C11.6007 9.76417 11.2853 9.63952 10.8966 9.625Z"
        fill="#FAA61A"
      />
    </svg>
  )
}

import { Typography } from "@hero/krypton"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { usePayTranslation } from "../../../01_technical/translations"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1rem 0;
  height: 3.625rem;
  box-shadow: 0px -1px 0px 0px rgba(233, 234, 240, 1) inset;
`

const Logo = styled.h1`
  position: absolute;
  left: 0;
  margin-left: 3.5rem;
  @media (max-width: 900px) {
    display: none;
  }
`

const Steps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 8.5rem;
  margin-top: 0.5rem;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Dot = styled.div`
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 100%;
  background-color: black;
  margin-top: 0.25rem;
`

const StyledArrow = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const StyledInstallmentTypography = styled(Typography)<{ $hasChosenPaymentType: boolean }>`
  cursor: ${({ $hasChosenPaymentType }) => ($hasChosenPaymentType ? `pointer` : `default`)};
`

const StyledPaymentTypography = styled(Typography)<{ $hasChosenPaymentType: boolean }>`
  color: ${({ theme, $hasChosenPaymentType }) =>
    $hasChosenPaymentType ? theme.colors.grey.$600 : theme.colors.grey.$500};
`

export const Header = ({
  merchantTradingName,
  $hasChosenPaymentType,
}: {
  merchantTradingName: string
  $hasChosenPaymentType: boolean
}) => {
  const { t } = usePayTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const skipPaymentTypeSelection = location.state?.skipPaymentTypeSelection ?? false

  return (
    <Container>
      <Logo>{merchantTradingName}</Logo>
      <Steps>
        {!skipPaymentTypeSelection && (
          <>
            {" "}
            <div>
              <StyledInstallmentTypography
                as="a"
                $variant="caption-2"
                onClick={() => $hasChosenPaymentType && navigate(-1)}
                $hasChosenPaymentType={$hasChosenPaymentType}
              >
                {t("installmentTimeline")}
              </StyledInstallmentTypography>
              {!$hasChosenPaymentType && <Dot />}
            </div>
            <StyledArrow as="span" $variant="caption-2">
              &gt;
            </StyledArrow>
          </>
        )}
        <div>
          <StyledPaymentTypography $hasChosenPaymentType={$hasChosenPaymentType} as="p" $variant="caption-2">
            {t("payment")}
          </StyledPaymentTypography>
          {$hasChosenPaymentType && !skipPaymentTypeSelection && <Dot />}
        </div>
      </Steps>
    </Container>
  )
}

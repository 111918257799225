export const MastercardLogo = () => {
  return (
    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="36.5" height="24.5" rx="4.25" fill="#F8F8F8" stroke="#E1E1E1" strokeWidth="0.5" />
      <path d="M21.9758 7.65234H16.0273V18.3408H21.9758V7.65234Z" fill="#FF5F00" />
      <path
        d="M16.4034 12.9975C16.4034 10.8258 17.4232 8.89965 18.9906 7.6533C17.8386 6.74686 16.3845 6.19922 14.7983 6.19922C11.0403 6.19922 8 9.23956 8 12.9975C8 16.7554 11.0403 19.7958 14.7983 19.7958C16.3845 19.7958 17.8386 19.2481 18.9906 18.3417C17.4232 17.1142 16.4034 15.1692 16.4034 12.9975Z"
        fill="#EB001B"
      />
      <path
        d="M29.9984 12.9975C29.9984 16.7554 26.958 19.7958 23.2001 19.7958C21.6138 19.7958 20.1597 19.2481 19.0078 18.3417C20.5941 17.0954 21.5949 15.1692 21.5949 12.9975C21.5949 10.8258 20.5752 8.89965 19.0078 7.6533C20.1597 6.74686 21.6138 6.19922 23.2001 6.19922C26.958 6.19922 29.9984 9.25845 29.9984 12.9975Z"
        fill="#F79E1B"
      />
    </svg>
  )
}

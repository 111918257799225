import { Typography } from "@hero/krypton"
import i18next from "i18next"
import styled from "styled-components"
import { usePayTranslation } from "../../01_technical/translations"
import herosvg from "../../assets/hero.svg"
import { PaymentType } from "../../business/enum/PaymentType"
import { SupportedLanguage } from "../../business/enum/SupportedLanguage"
import { toLocalDate } from "../../utils/date.converter"
import { AvailablePaymentType } from "../business/Installment.type"

const MerchantLogo = styled.img`
  max-height: 72px;
  max-width: 100%;
  background: #ffffff;
  box-shadow: 0px 2.7px 7.2px -2.7px rgba(0, 0, 0, 0.25);
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  border-radius: 5px;
`

const HeroLogo = styled.div`
  width: 72px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 2.7px 7.2px -2.7px rgba(0, 0, 0, 0.25);
  background-image: url(${herosvg});
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  border-radius: 999px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledMerchantTradingName = styled(Typography)`
  margin: 0.5rem 0;
`

export const PaymentHeader = ({
  logoUrl,
  merchantTradingName,
  amount,
  paymentTypeSelected,
}: {
  logoUrl?: string
  merchantTradingName: string
  amount: number
  paymentTypeSelected: AvailablePaymentType
}) => {
  const { t } = usePayTranslation()
  const currentLanguage = i18next.language as SupportedLanguage

  const payNDInstallmentDueDate =
    paymentTypeSelected.type === PaymentType.PAY_ND ? paymentTypeSelected.installments[0].dueDate : ""

  const getPaymentTypeTitle = (paymentType: PaymentType, installmentCount: number, dueDate: string) => {
    switch (paymentType) {
      case PaymentType.PAY_1X:
        return t("paymentType.pay_1x.title", { amount })
      case PaymentType.PAY_NX:
        return t("paymentType.pay_nx.title", { amount, installmentCount })
      case PaymentType.PAY_ND:
        return t("paymentType.pay_nd.title", {
          amount,
          dueDate: toLocalDate(dueDate, currentLanguage),
        })
      default:
        return t("paymentType.error")
    }
  }
  return (
    <Container>
      {logoUrl ? <MerchantLogo alt="logo" src={logoUrl} /> : <HeroLogo />}
      <StyledMerchantTradingName $variant="body-4-regular">{merchantTradingName}</StyledMerchantTradingName>
      <Typography $variant="title-3-semibold">
        {getPaymentTypeTitle(paymentTypeSelected.type, paymentTypeSelected.installmentCount, payNDInstallmentDueDate)}
      </Typography>
    </Container>
  )
}

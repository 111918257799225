import { Typography } from "@hero/krypton"
import i18next from "i18next"
import styled from "styled-components"
import { usePayTranslation } from "../../../01_technical/translations"
import { HeroLogo } from "../../../assets/logos/Hero"
import { SupportedLanguage } from "../../../business/enum/SupportedLanguage"
import { LANDING_URI } from "../../../env_variables"

const CGU = "conditions-generales-dutilisation"

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 0px 0px rgba(233, 234, 240, 1) inset;

  & > svg {
    position: absolute;
    right: 10px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    > a,
    svg {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      margin-left: unset;
      margin-right: unset;
    }
  }
`

const TermsAndConditions = styled(Typography)`
  margin-left: 3rem;
  margin-right: 1rem;
`

const PrivacyPolicy = styled(Typography)`
  margin-right: 1rem;
`

const ContactHero = styled(Typography)``

export const Footer = ({ privacyPolicyUrl }: { privacyPolicyUrl: string }) => {
  const { t } = usePayTranslation()
  const lang = i18next.language as SupportedLanguage
  const cgurUrl = lang === SupportedLanguage.FR ? `${LANDING_URI}/${CGU}` : `${LANDING_URI}/${lang}/${CGU}`

  return (
    <Container>
      <TermsAndConditions as="a" $variant="caption-2" href={cgurUrl} target="_blank" rel="noreferrer">
        {t("termsAndConditions")}
      </TermsAndConditions>
      <PrivacyPolicy as="a" $variant="caption-2" target="_blank" rel="noreferrer" href={privacyPolicyUrl}>
        {t("privacyPolicy")}
      </PrivacyPolicy>
      <ContactHero as="a" $variant="caption-2" href="mailto:support@hero.fr">
        {t("contactHero")}
      </ContactHero>
      <HeroLogo />
    </Container>
  )
}

import { Typography } from "@hero/krypton"
import i18next from "i18next"
import styled from "styled-components"
import { centsToEurosFormatted } from "../../00_shared/utils/currency.converter"
import { SupportedLanguage } from "../../business/enum/SupportedLanguage"
import { toLocalDate } from "../../utils/date.converter"

type Installment = {
  amount: number
  position: number
  dueDate: string
}

const SliderContainer = styled.div`
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1rem;
  @media (max-width: 900px) {
    flex-direction: row;
    margin-left: 2rem;
  }
`

const Slider = styled.div`
  width: 80%;
  border-radius: 25px;
  height: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.grey.$200};
  @media (max-width: 900px) {
    flex-direction: column;
    width: 4px;
    height: 15rem;
    margin-right: 1rem;
    align-items: center;
  }
}
`

const Dot = styled.div<{ $isFirstInstallment: boolean }>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  border: 1px solid white;
  margin-top: -4px;
  background: ${({ theme, $isFirstInstallment }) =>
    $isFirstInstallment ? theme.colors.primary.$200 : theme.colors.grey.$200};
`

const Legend = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-right: -15px;
  & > * {
    text-align: center;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: unset;
    width: 35%;
    height: 15rem;
    & > :first-child {
      margin-top: -15px;
    }
    & > :last-child {
      margin-bottom: -15px;
    }
    & > * {
      text-align: unset;
      margin-left: 0.5rem;
    }
  }
`

const InstallmentInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledAmount = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$600};
`

const StyledDueDate = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const InstallmentsSlider = ({ installments }: { installments: Installment[] }) => {
  const currentLanguage = i18next.language as SupportedLanguage

  return (
    <SliderContainer>
      <Slider>
        {installments.map((install) => (
          <div key={install.position}>
            <Dot $isFirstInstallment={install.position === 1} />
          </div>
        ))}
      </Slider>
      <Legend>
        {installments.map((install) => (
          <InstallmentInfo key={install.position}>
            <StyledAmount $variant="body-2-bold">{centsToEurosFormatted(install.amount)}</StyledAmount>
            <StyledDueDate $variant="body-4-regular">{toLocalDate(install.dueDate, currentLanguage)}</StyledDueDate>
          </InstallmentInfo>
        ))}
      </Legend>
    </SliderContainer>
  )
}

import { Spinner, Typography } from "@hero/krypton"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { centsToEuros } from "../../../00_shared/utils/currency.converter"
import { usePayTranslation } from "../../../01_technical/translations"
import { PaymentStatus } from "../../../business/enum/PaymentStatus"
import { PaymentType } from "../../../business/enum/PaymentType"
import { SupportedLanguage } from "../../../business/enum/SupportedLanguage"
import { API_URI, LANDING_URI } from "../../../env_variables"
import { AvailablePaymentType } from "../../business/Installment.type"
import { AttachmentContainer } from "../../components/Attachments"
import { CustomerInfos } from "../../components/CustomerInfos"
import { InstallmentsSlider } from "../../components/InstallmentsSlider"
import { ItemWithSeparator } from "../../components/ItemWithSeparator"
import { PaymentHeader } from "../../components/PaymentHeader"
import { PaymentInfosBanner } from "../../components/PaymentInfosBanner"
import { Card3DSInfos } from "../../shared/Card3DSInfos/Card3DSInfos"
import { CardPayment } from "../../shared/CardPayment/CardPayment"
import { Footer } from "../../shared/Footer/Footer"
import { Header } from "../../shared/Header/Header"
import { CheckoutComCardInputs } from "../CheckoutComCardInputs/CheckoutComCardInputs"
import { useGetPaymentLink } from "../requests.ts/GetPaymentLinkConfiguration"

const PRIVACY_POLICY = "politique-de-confidentialite"

const FullPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 2rem 4rem;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100vw;
    flex-direction: column;
    padding-bottom: 3rem;
    margin: 0;
    align-items: center;
  }
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
  padding: 1rem;
  max-width: 37rem;
  height: fit-content;

  @media (max-width: 900px) {
    width: 100%;
    max-width: unset;
    border-radius: unset;
  }
`
const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  overflow: hidden;
  margin-left: 2rem;
  width: 26rem;

  @media (max-width: 900px) {
    width: unset;
    margin-left: unset;
  }
`

const PaymentInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem;
  background: linear-gradient(180deg, #f8f8f8 0%, rgba(248, 248, 248, 0.1) 100%);
  border-radius: 1rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
`

const ContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$600};
`
const InformationsContent = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const PrivacyPolicyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  justify-content: center;
`
const PrivacyPolicyText = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin-right: 0.2rem;
`

const PrivacyPolicyUrl = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin-right: 0.2rem;
  text-decoration: underline;
`
const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const BottomLeftInfo = styled.div`
  padding: 0 0.75rem;
`

const AttachementContainerBox = styled.div`
  align-self: flex-start;
`

export const PaymentLinkDetailsPage = () => {
  const { t } = usePayTranslation()
  const paymentLinkId = useParams<{ id: string }>().id

  const [searchParams] = useSearchParams()
  const paymentFeeConfigurationId = searchParams.get("paymentFeeConfigurationId")
  const cardAddedFailed = searchParams.get("cardAddedFailed")
  const cardAddedSuccess = searchParams.get("cardAddedSuccess")

  const { state } = useLocation()

  const { data, loading } = useGetPaymentLink({ paymentLinkId })

  const [paymentTypeSelected, setPaymentTypeSelected] = useState<AvailablePaymentType | null>(null)

  const lang = i18next.language as SupportedLanguage
  const privacyPolicyUrl =
    lang === SupportedLanguage.FR ? `${LANDING_URI}/${PRIVACY_POLICY}` : `${LANDING_URI}/${lang}/${PRIVACY_POLICY}`

  useEffect(() => {
    if (state && state.paymentTypeSelected) {
      setPaymentTypeSelected(state.paymentTypeSelected)
    } else if (!data?.availablePaymentTypesWithInstallments) return
    else if (paymentFeeConfigurationId) {
      const selectedPaymentType = data.availablePaymentTypesWithInstallments.find(
        (paymentType) => paymentType.feeConfigurationId === paymentFeeConfigurationId,
      )
      if (!selectedPaymentType) {
        return
      }
      setPaymentTypeSelected(selectedPaymentType)
    } else {
      const selectedPaymentType = data.selectedFeeConfiguration
      if (!selectedPaymentType) {
        return
      }
      setPaymentTypeSelected(selectedPaymentType)
    }
  }, [data?.availablePaymentTypesWithInstallments, state, data?.selectedFeeConfiguration, paymentFeeConfigurationId])

  if (loading) {
    return (
      <CenteredContent>
        <Spinner />
      </CenteredContent>
    )
  }

  if (!paymentLinkId || !data || !paymentTypeSelected) {
    return <CenteredContent>{t("notFound")}</CenteredContent>
  }

  const { merchantTradingName, customerInfos, amount, attachmentPath, isInitiated, paymentStatus } = data

  const isPaymentInitiating3DSFailed = !!paymentFeeConfigurationId
  const isNotInitiatedAndNot3DSFailed = !isInitiated && !isPaymentInitiating3DSFailed
  const isNewCardAdded3DSFailed = !!cardAddedFailed
  const isNewCardAdded3DSSuccess = !!cardAddedSuccess

  return (
    <FullPage>
      {isNotInitiatedAndNot3DSFailed ? (
        <Header merchantTradingName={merchantTradingName} $hasChosenPaymentType={true} />
      ) : (
        <PaymentInfosBanner
          status={paymentStatus}
          isPaymentInitiating3DSFailed={isPaymentInitiating3DSFailed}
          cardAdded3DSFailed={isNewCardAdded3DSFailed}
          cardAdded3DSSuccess={isNewCardAdded3DSSuccess}
        />
      )}
      <Container>
        <LeftSide>
          <PaymentInfos>
            <PaymentHeader
              merchantTradingName={merchantTradingName}
              amount={centsToEuros(amount)}
              paymentTypeSelected={paymentTypeSelected}
            />
            {paymentTypeSelected.type === PaymentType.PAY_NX && (
              <InstallmentsSlider installments={paymentTypeSelected.installments} />
            )}
          </PaymentInfos>
          <BottomLeftInfo>
            <ItemWithSeparator>
              <CustomerInfos
                name={customerInfos.name}
                siret={paymentTypeSelected.type !== PaymentType.PAY_1X ? customerInfos.siret : undefined}
                billingAddress={customerInfos.billingAddress}
                shippingAddress={customerInfos.shippingAddress}
              />
            </ItemWithSeparator>

            <ItemWithSeparator>
              <div>
                <ContentTitle $variant="body-3-semibold">{t("paymentPage.informations.title")}</ContentTitle>
                <InformationsContent $variant="body-4-regular">
                  {t("paymentPage.informations.content", { merchantTradingName })}
                </InformationsContent>
              </div>
            </ItemWithSeparator>

            <ItemWithSeparator>
              <AttachementContainerBox>
                <AttachmentContainer
                  downloadUrl={attachmentPath ? `${API_URI}/paymentLink/${paymentLinkId}` : undefined}
                />
              </AttachementContainerBox>
            </ItemWithSeparator>

            <PrivacyPolicyContainer>
              <PrivacyPolicyText $variant="caption-2">{t("paymentPage.privacyPolicy.first")}</PrivacyPolicyText>
              <PrivacyPolicyUrl as="a" $variant="caption-2" target="_blank" rel="noreferrer" href={privacyPolicyUrl}>
                {t("paymentPage.privacyPolicy.second")}
              </PrivacyPolicyUrl>
              <PrivacyPolicyText $variant="caption-2">{t("paymentPage.privacyPolicy.third")}</PrivacyPolicyText>
            </PrivacyPolicyContainer>
          </BottomLeftInfo>
        </LeftSide>
        <RightSide>
          {paymentStatus !== PaymentStatus.FINISHED && (
            <CardPayment
              paymentId={paymentLinkId}
              amount={centsToEuros(amount)}
              feeConfigurationId={paymentTypeSelected.feeConfigurationId}
              isInitiated={isInitiated}
              CheckoutComCardInputs={CheckoutComCardInputs}
            />
          )}
          <Card3DSInfos />
        </RightSide>
      </Container>
      <Footer privacyPolicyUrl={privacyPolicyUrl} />
    </FullPage>
  )
}

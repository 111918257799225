import { gql } from "@apollo/client"

export type INIT_PAYMENT_CHECKOUT_RESPONSE = {
  initiatePaymentCheckout: {
    paymentId?: string
    redirectUrl?: string
    errorCode?: string
    message?: string
  }
}

export type INIT_PAYMENT_CHECKOUT_ARGS = {
  paymentCheckoutId: string
  paymentFeeConfigurationId: string
  card: {
    token: string
    issuer?: string
    issuerCountry?: string
    cardType?: string
    productType?: string
    expiryMonth: number
    expiryYear: number
    cardCategory?: string
  }
}

export const INIT_PAYMENT_CHECKOUT = gql`
  mutation initiatePaymentCheckout(
    $paymentCheckoutId: String!
    $paymentFeeConfigurationId: String!
    $card: PaymentCheckoutCardInput!
  ) {
    initiatePaymentCheckout(
      paymentCheckoutId: $paymentCheckoutId
      paymentFeeConfigurationId: $paymentFeeConfigurationId
      card: $card
    ) {
      ... on InitiatePaymentCheckoutOutput {
        paymentId
        redirectUrl
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

import { Typography } from "@hero/krypton"
import { t } from "i18next"
import styled from "styled-components"
import { CustomerAddress } from "../business/address.type"

const ContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$600};
`

const CustomerContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const MainInfosContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  > span {
    display: inline-flex;
    :first-child {
      margin-right: 0.3rem;
    }
  }
`

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const CustomerInfos = ({
  name,
  siret,
  billingAddress,
  shippingAddress,
}: {
  name: string
  siret?: string
  billingAddress: CustomerAddress
  shippingAddress?: CustomerAddress
}) => {
  return (
    <CustomerContainer>
      <MainInfosContainer>
        <ContentTitle $variant="body-3-semibold">{t("paymentPage.customerInfos.title")}</ContentTitle>
        <StyledTypography $variant="body-4-regular">{name}</StyledTypography>
        <StyledTypography $variant="body-4-regular">{billingAddress.line1}</StyledTypography>
        {billingAddress.line2 && <StyledTypography $variant="body-4-regular">{billingAddress.line2}</StyledTypography>}
        <span>
          <StyledTypography $variant="body-4-regular">{billingAddress.zipCode}</StyledTypography>
          <StyledTypography $variant="body-4-regular">{billingAddress.city}</StyledTypography>
        </span>
        {siret && <StyledTypography $variant="body-4-regular">SIRET: {siret}</StyledTypography>}
      </MainInfosContainer>
      {shippingAddress && (
        <MainInfosContainer>
          <ContentTitle $variant="body-3-semibold">{t("paymentPage.customerDelivery.title")}</ContentTitle>
          <StyledTypography $variant="body-4-regular">{shippingAddress.line1}</StyledTypography>
          {shippingAddress.line2 && (
            <StyledTypography $variant="body-4-regular">{shippingAddress.line2}</StyledTypography>
          )}
          <span>
            <StyledTypography $variant="body-4-regular">{shippingAddress.zipCode}</StyledTypography>
            <StyledTypography $variant="body-4-regular">{shippingAddress.city}</StyledTypography>
          </span>
        </MainInfosContainer>
      )}
    </CustomerContainer>
  )
}

export const LockLogo = ({ color = "#3D3E42" }: { color?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_21226_28490)">
        <path
          d="M7.74812 10.5V10.3427C7.74812 8.78147 7.65419 7.04125 8.74458 5.9239C9.36641 5.2867 10.3726 4.75 11.9981 4.75C13.6236 4.75 14.6298 5.2867 15.2516 5.9239C16.342 7.04125 16.2481 8.78147 16.2481 10.3427V10.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.75 11.75C5.75 11.1977 6.19772 10.75 6.75 10.75H17.25C17.8023 10.75 18.25 11.1977 18.25 11.75V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V11.75Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

import { gql } from "@apollo/client"

export type ADD_NEW_CARD_RESPONSE = {
  addNewCardToPaymentLink: {
    redirectUrl: string
  }
}

export type ADD_NEW_CARD_ARGS = {
  paymentLinkId: string
  cardTokenId: string
}

export const ADD_NEW_CARD = gql`
  mutation addNewCardToPaymentLink($paymentLinkId: String!, $cardTokenId: String!) {
    addNewCardToPaymentLink(paymentLinkId: $paymentLinkId, cardTokenId: $cardTokenId) {
      ... on AddNewCardOutput {
        redirectUrl
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

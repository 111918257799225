import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import * as Sentry from "@sentry/react"
import { useMemo } from "react"
import { API_URI } from "../env_variables"

const httpLink = createHttpLink({ uri: `${API_URI}/graphql`, credentials: "include" })

const getErrorLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    try {
      const error = new Error(JSON.stringify({ graphQLErrors, networkError }))
      error.name = "GraphQL Error"
      Sentry.captureException(error)
    } catch (e) {
      console.error("SENTRY_CANNOT_STRINGIFY_ERROR", e)
    }
  })

const getClient = () => {
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([getErrorLink(), httpLink]),
    cache: new InMemoryCache(),
    queryDeduplication: true,
  })

  return client
}

export const Connect = ({ children }: { children: JSX.Element }) => {
  const client = useMemo(() => {
    return getClient()
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

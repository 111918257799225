import { Typography } from "@hero/krypton"
import styled from "styled-components"

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.danger.$200};
  padding: 0.5rem;
`

const ErrorContainer = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.danger.$200}`};
  background-color: ${({ theme }) => theme.colors.danger.$100};
  color: ${({ theme }) => theme.colors.danger.$200};
  border-radius: 8px;
  margin-top: 1.5rem;
`

export const ErrorElement = ({ message }: { message: string }) => {
  return (
    <ErrorContainer>
      <StyledTypography>{message}</StyledTypography>
    </ErrorContainer>
  )
}

import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { PaymentStatus } from "../../../business/enum/PaymentStatus"
import { PaymentType } from "../../../business/enum/PaymentType"
import { CustomerAddress } from "../../business/address.type"

export const useGetPaymentLink = (variables: { paymentLinkId?: string }) => {
  const { data, error, loading } = useHeroQuery<getPaymentLinkConfigurationResponse>({
    gqlQuerySchema: GET_PAYMENT_LINK_CONFIGURATION,
    variables: {
      paymentLinkId: variables.paymentLinkId,
    },
    skip: !variables.paymentLinkId,
  })

  return {
    data,
    error,
    loading,
  }
}

type getPaymentLinkConfigurationResponse = {
  amount: number
  merchantTradingName: string
  merchantLogo?: string
  attachmentPath?: string
  customerInfos: {
    name: string
    siret?: string
    isIndividual: boolean
    billingAddress: CustomerAddress
    shippingAddress?: CustomerAddress
  }
  availablePaymentTypesWithInstallments: {
    feeConfigurationId: string
    type: PaymentType
    installmentCount: number
    daysBeforeDueDate: number
    installments: {
      amount: number
      dueDate: string
      position: number
    }[]
  }[]
  isInitiated: boolean
  paymentStatus: PaymentStatus | null
  selectedFeeConfiguration: {
    feeConfigurationId: string
    type: PaymentType
    installmentCount: number
    daysBeforeDueDate: number
    installments: {
      amount: number
      dueDate: string
      position: number
    }[]
  } | null
}

const GET_PAYMENT_LINK_CONFIGURATION = gql`
  query customerGetPaymentLinkConfiguration($paymentLinkId: String!) {
    customerGetPaymentLinkConfiguration(paymentLinkId: $paymentLinkId) {
      ... on CustomerGetPaymentLinkOutput {
        amount
        merchantTradingName
        merchantLogo
        attachmentPath
        customerInfos {
          name
          siret
          isIndividual
          billingAddress {
            line1
            line2
            city
            zipCode
            country
          }
          shippingAddress {
            line1
            line2
            city
            zipCode
            country
          }
        }
        availablePaymentTypesWithInstallments {
          feeConfigurationId
          type
          installmentCount
          daysBeforeDueDate
          installments {
            amount
            dueDate
            position
          }
        }
        isInitiated
        paymentStatus
        selectedFeeConfiguration {
          feeConfigurationId
          type
          installmentCount
          daysBeforeDueDate
          installments {
            amount
            dueDate
            position
          }
        }
      }
      ... on SimpleApiError {
        errorCode
      }
    }
  }
`

import styled from "styled-components"

export const GradientLine = styled.div`
  height: 8px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background: linear-gradient(90deg, #a97eff 0%, #df5ea3 24.57%, #ffb571 49.48%, #f1dfce 63.76%, #9effad 100%);

  @media (max-width: 900px) {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }
`

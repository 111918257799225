import { gql } from "@apollo/client"

export type ADD_NEW_CARD_RESPONSE = {
  addNewCardToPaymentCheckout: {
    redirectUrl: string
  }
}

export type ADD_NEW_CARD_ARGS = {
  paymentCheckoutId: string
  cardTokenId: string
}

export const ADD_NEW_CARD = gql`
  mutation addNewCardToPaymentCheckout($paymentCheckoutId: String!, $cardTokenId: String!) {
    addNewCardToPaymentCheckout(paymentCheckoutId: $paymentCheckoutId, cardTokenId: $cardTokenId) {
      ... on PaymentCheckoutAddNewCardOutput {
        redirectUrl
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

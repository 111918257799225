import { gql } from "@apollo/client"

export type INIT_PAYMENT_LINK_RESPONSE = {
  initiatePaymentLink: {
    paymentId?: string
    redirectUrl?: string
    errorCode?: string
    message?: string
  }
}

export type INIT_PAYMENT_LINK_ARGS = {
  paymentLinkId: string
  paymentFeeConfigurationId: string
  card: {
    token: string
    issuer?: string
    issuerCountry?: string
    cardType?: string
    productType?: string
    expiryMonth: number
    expiryYear: number
    cardCategory?: string
  }
}

export const INIT_PAYMENT_LINK = gql`
  mutation initiatePaymentLink($paymentLinkId: String!, $paymentFeeConfigurationId: String!, $card: CardInput!) {
    initiatePaymentLink(
      paymentLinkId: $paymentLinkId
      paymentFeeConfigurationId: $paymentFeeConfigurationId
      card: $card
    ) {
      ... on InitiatePaymentLinkOutput {
        paymentId
        redirectUrl
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

import { Outlet, RouteObject } from "react-router-dom"
import { Connect } from "./01_technical/client"
import { RouterConnectedTranslation } from "./01_technical/translations"
import { PaymentCheckoutDetailsPage } from "./Payment/Checkout/PaymentCheckoutDetails/PaymentCheckoutDetails.screen"
import { PaymentCheckoutPage } from "./Payment/Checkout/PaymentCheckoutPage/PaymentCheckoutPage.screen"
import { PaymentLinkDetailsPage } from "./Payment/Link/PaymentLinkDetails/PaymentLinkDetails.screen"
import { PaymentLinkPage } from "./Payment/Link/PaymentLinkPage/PaymentLinkPage.screen"
import NotFoundComponent from "./Payment/NotFoundPage/NotFoundPage"
import { Payment } from "./Payment/Payment"
import { PaymentConfirmPage } from "./Payment/PaymentConfirm/PaymentConfirmPage.screen"

const withConnect = (component: JSX.Element) => <Connect>{component}</Connect>

export const appRoutes: RouteObject[] = [
  {
    path: "/:lang?/*",
    element: <RouterConnectedTranslation />,
    children: [
      {
        path: "*",
        element: <Outlet />,
        children: [
          {
            path: "*",
            element: withConnect(<Payment />),
            children: [
              { path: "link/:id", element: <PaymentLinkPage /> },
              { index: true, path: "link/:id/details", element: <PaymentLinkDetailsPage /> },
              { path: "checkout/:id", element: <PaymentCheckoutPage /> },
              { index: true, path: "checkout/:id/details", element: <PaymentCheckoutDetailsPage /> },
              { path: ":id/success", element: <PaymentConfirmPage /> },
              { path: "*", element: <NotFoundComponent /> },
            ],
          },
        ],
      },
    ],
  },
]

import { ApolloQueryResult, gql, useQuery } from "@apollo/client"
import { createContext, useContext } from "react"
import { unwrapGraphQLResponse } from "../01_technical/requesting/DEPRECATED_graphql.errors"
import { GqlHeroError, ValidationGraphQLError } from "../01_technical/requesting/request-error.type"

const DASHBOARD_FEATURE_FLAGS_QUERY = gql`
  query {
    payGetFeatureFlags {
      ... on GetFeatureFlagsOutput {
        featureFlags {
          name
          isActive
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

type FeatureFlag = {
  name: string
  isActive: boolean
}

type FeatureFlagsResponse = {
  featureFlags: FeatureFlag[]
}

type getFeatureFlagsResponse = {
  payGetFeatureFlags: FeatureFlagsResponse | GqlHeroError | ValidationGraphQLError
}

type FFContextType = { featureFlags: FeatureFlag[]; refetch: () => Promise<ApolloQueryResult<unknown>> }

const FFContext = createContext<FFContextType>({} as FFContextType)

export const useFeatureFlagContext = () => useContext(FFContext)

export const FeatureFlagProvider: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  const { data, loading, error, refetch } = useQuery<getFeatureFlagsResponse>(DASHBOARD_FEATURE_FLAGS_QUERY)

  const { data: unwrappedData } = unwrapGraphQLResponse<FeatureFlagsResponse>(data?.payGetFeatureFlags)

  if (loading) {
    return <div>Chargement...</div>
  }

  if (error) {
    console.error(error)
    return <div>{error.message}</div>
  }

  if (!data) {
    console.error("FFProvider - data is empty")
    // eslint-disable-next-line i18next/no-literal-string
    return <>Une erreur inattendue est survenue</>
  }

  const featureFlags = unwrappedData?.featureFlags ?? []

  return <FFContext.Provider value={{ featureFlags, refetch }}>{children}</FFContext.Provider>
}

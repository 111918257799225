export const CBLogo = () => {
  return (
    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="36.5" height="24.5" rx="4.25" fill="#146C9C" stroke="#E1E1E1" strokeWidth="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0701 8.50672C20.0645 8.51238 20.0599 9.48772 20.0599 10.6741V12.8312L24.1843 12.8271L28.3088 12.823L28.4811 12.7759C29.4969 12.4984 30.1224 11.6931 30.1217 10.6639C30.1215 10.2769 30.1068 10.1986 29.9614 9.80915C29.7411 9.21912 29.1111 8.70457 28.4062 8.53889L28.2638 8.50545L24.1721 8.50092C21.9217 8.49846 20.0758 8.50112 20.0701 8.50672ZM11.7585 8.55681C10.2029 8.75713 8.9313 9.8306 8.47224 11.3312C8.323 11.819 8.30482 11.9995 8.30469 12.9957C8.30456 13.9815 8.31341 14.0848 8.44055 14.5777C8.77692 15.8814 9.87936 16.988 11.1966 17.344C11.4078 17.4011 11.426 17.4051 11.6125 17.4358C12.1099 17.5178 16.0702 17.5228 16.5535 17.442C18.5145 17.1145 19.8494 15.5502 19.8904 13.5318L19.8989 13.1157L16.9937 13.1119L14.0885 13.1082V12.9732V12.8382L16.9937 12.8344L19.8989 12.8306L19.891 12.4595C19.8786 11.8748 19.7892 11.4211 19.5963 10.9638C19.0382 9.64021 17.8584 8.74441 16.4262 8.55655C16.1008 8.51391 12.09 8.51411 11.7585 8.55681ZM20.0669 13.1341C20.0629 13.1445 20.0614 14.1215 20.0635 15.3049L20.0674 17.4568H24.0832C28.3801 17.4568 28.2439 17.4591 28.5261 17.3829C29.8065 17.0369 30.483 15.6333 29.9539 14.4203C29.7339 13.9158 29.2674 13.457 28.7958 13.2813C28.641 13.2236 28.5189 13.186 28.3912 13.1566L28.2488 13.1238L24.1615 13.1194C20.8719 13.116 20.0727 13.1188 20.0669 13.1341Z"
        fill="white"
      />
    </svg>
  )
}

import { Separator } from "@hero/krypton"
import styled from "styled-components"

const Container = styled.div`
  > div {
    margin-top: 1.5rem;
  }
`

const StyledSeparator = styled(Separator)`
  margin-top: 1.5rem;
`

export const ItemWithSeparator = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container>
      {children}
      <StyledSeparator />
    </Container>
  )
}

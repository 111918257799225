/* eslint-disable i18next/no-literal-string */
import { Typography } from "@hero/krypton"
import confetti from "canvas-confetti"
import { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { useQuerySearchParams } from "../../00_shared/hooks/useQuerySearchParams.hook"
import { usePayTranslation } from "../../01_technical/translations"
import confirmationSuccess from "../../assets/confirmation-success.png"
import { CashLogo } from "../../assets/logos/Cash"
import { CheckLogo } from "../../assets/logos/Check"
import { CheckboxLogo } from "../../assets/logos/Checkbox"
import { HeartLogo } from "../../assets/logos/Heart"
import { LockLogo } from "../../assets/logos/Lock"
import { GradientLine } from "../../ui/GradientLine"
import { Footer } from "../shared/Footer/Footer"

const Fullpage = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem;
  max-width: 31.5rem;
  align-self: center;
  @media (max-width: 900px) {
    max-width: 27rem;
  }
`
const TopContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
  border-radius: 1rem;
  max-width: inherit;
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
`
const BottomContainer = styled.div`
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
  border-radius: 1rem;
  padding: 1rem 3rem;
  max-width: inherit;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
`
const PaymentValidated = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  background: linear-gradient(180deg, #f8f8f8 0%, rgba(248, 248, 248, 0.1) 100%);
  border-radius: 1rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
  margin: 1rem;
  > img {
    max-width: 29rem;
  }
  @media (max-width: 900px) {
    > img {
      max-width: 20rem;
    }
  }
`

const Round = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey.$200};
  margin: 0.5rem;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
`

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$600};
  display: flex;
  align-items: center;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$600};
`
const StyledPaymentConfirmation = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey.$600};
  text-align: center;
`

const Gradient = styled(GradientLine)<{ width: number }>`
  top: 0;
  left: 0;
  height: 8px;
  position: absolute;
  transition: 10s all ease-in-out;
  border-radius: 0rem;

  ${({ width }) => css`
    right: ${100 - width}%;
  `}

  @media (max-width: 1000px) {
    background: 0;
  }
`

export const PaymentConfirmPage = () => {
  const { t } = usePayTranslation()
  const { getSearchParam } = useQuerySearchParams<{
    redirectUri: string
  }>()
  const [width, setWidth] = useState(0)

  const redirectUri = getSearchParam("redirectUri")

  const items = [
    {
      logo: <CheckLogo />,
      text: t("confirmPage.textItem1"),
    },
    {
      logo: <LockLogo />,
      text: t("confirmPage.textItem2"),
    },
    {
      logo: <HeartLogo />,
      text: t("confirmPage.textItem3"),
    },
    {
      logo: <CheckboxLogo />,
      text: t("confirmPage.textItem4"),
    },
    {
      logo: <CashLogo />,
      text: t("confirmPage.textItem5"),
    },
  ]

  useEffect(() => {
    if (!redirectUri) {
      return
    }

    const timeSetWidth = setTimeout(() => {
      setWidth(100)
    }, 100)

    const timer = setTimeout(() => {
      const decodedUri = decodeURIComponent(redirectUri)
      // Redirect to the provided uri
      window.location.href = `${!decodedUri.startsWith("http") ? "https://" : ""}${decodedUri}`
    }, 10_000)

    return () => {
      clearTimeout(timer)
      clearTimeout(timeSetWidth)
    }
  }, [redirectUri, setWidth])

  useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 100,
      origin: { y: 0 },
    })
  }, [])

  return (
    <Fullpage>
      <MainContent>
        <TopContainer>
          {redirectUri && <Gradient width={width} />}
          <PaymentValidated>
            <img src={confirmationSuccess} alt="payment success" />
            <StyledTitle $variant="title-2-bold">{t("confirmPage.title")}</StyledTitle>
            <StyledPaymentConfirmation $variant="body-3-regular">
              {t(redirectUri ? "confirmPage.paymentCheckoutConfirmation" : "confirmPage.paymentConfirmation")}
            </StyledPaymentConfirmation>
          </PaymentValidated>
        </TopContainer>
        <BottomContainer>
          {items.map(({ logo, text }, i) => (
            <Item key={i}>
              <Round>{logo}</Round>
              <StyledTypography $variant="caption-2">{text}</StyledTypography>
            </Item>
          ))}
        </BottomContainer>
      </MainContent>
      <Footer privacyPolicyUrl="kdkkdk" />
    </Fullpage>
  )
}

export const CheckboxLogo = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#1D1E21" strokeLinejoin="round" />
      <path
        d="M5.14062 7.55469L7.26195 9.67601L10.7975 6.14047"
        stroke="#1D1E21"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

/* eslint-disable i18next/no-literal-string */
import { captureConsoleIntegration } from "@sentry/integrations"
import * as Sentry from "@sentry/react"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import Modal from "react-modal"
import "react-phone-number-input/style.css"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"
import { initI18next } from "./01_technical/translations"
import { App } from "./App"
import { SENTRY_ENABLED, SENTRY_ENV } from "./env_variables"
import "./index-reset.css"
import "./index.css"

Sentry.init({
  dsn: "https://8acdda2bc0064f78aed831665bc9bbc3@o1086518.ingest.sentry.io/6100378",
  integrations: [
    Sentry.browserTracingIntegration(),
    captureConsoleIntegration({
      levels: ["error"],
    }),
  ],
  maxValueLength: 5000,
  environment: SENTRY_ENV,
  enabled: SENTRY_ENABLED,
  ignoreErrors: ['"code":"HERO_FUNCTIONAL"'],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

initI18next()

Modal.setAppElement("#root")

const FatalErrorContainer = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
  font-family: Inter;
`

const container = document.getElementById("root")

if (!container) {
  throw new Error("No root element found")
}

createRoot(container).render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <FatalErrorContainer>
          <div lang="en">Please Refresh the page.</div>
          <div lang="fr">Veuillez rafraichir la page.</div>
          <div lang="es">Actualice la página.</div>
        </FatalErrorContainer>
      }
    >
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>,
)

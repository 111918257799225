/* eslint-disable i18next/no-literal-string */
import i18n from "i18next"
import { Outlet } from "react-router-dom"
import { TranslationNamespaces } from "../01_technical/translations"
import { FeatureFlagProvider } from "../FeatureFlags/featureFlags.context"

export const Payment = () => {
  i18n.setDefaultNamespace(TranslationNamespaces.PAY)
  i18n.loadNamespaces([TranslationNamespaces.PAY])

  return (
    <FeatureFlagProvider>
      <Outlet />
    </FeatureFlagProvider>
  )
}

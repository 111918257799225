export const HeroLogo = () => {
  return (
    <svg width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.76805 5.57088C2.96545 4.46739 3.92465 3.66406 5.04487 3.66406H51.1974C52.638 3.66406 53.728 4.96759 53.4741 6.38662L51.1844 19.1875C50.9871 20.291 50.0278 21.0944 48.9075 21.0944H2.75506C1.31452 21.0944 0.224413 19.7908 0.478247 18.3718L2.76805 5.57088Z"
        fill="black"
      />
      <path
        d="M5.92969 17.7419H9.28284L9.95346 13.934H13.1462L12.4756 17.7419H15.8288L17.6657 7.31041H14.3125L13.6857 10.9286H10.4783L11.1198 7.31041H7.76663L5.92969 17.7419ZM16.9208 17.7419H25.0996L25.5952 14.9699H20.7551L20.9883 13.686H24.9538L25.3912 11.1912H21.4257L21.6298 10.0824H26.4554L26.9365 7.31041H18.7577L16.9208 17.7419ZM26.0896 17.7419H29.4573L30.0404 14.4885H30.7111C30.8423 14.4885 30.9589 14.6052 31.0318 14.7948L32.1981 17.7419H35.974V17.7128L34.1809 13.7298C35.3909 13.3651 36.9654 12.4897 36.9654 10.1991C36.9654 8.24414 35.4492 7.31041 33.2187 7.31041H27.9265L26.0896 17.7419ZM30.4778 11.8915L30.7986 10.0095H32.5334C33.1458 10.0095 33.5394 10.2867 33.5394 10.8119C33.5394 11.5268 32.9563 11.8915 32.0523 11.8915H30.4778ZM41.9068 18.0191C45.5952 18.0191 48.3215 15.218 48.3215 11.6435C48.3215 8.62347 46.2513 7.0332 43.4813 7.0332C39.7928 7.0332 37.0812 9.87817 37.0812 13.4234C37.0812 16.4435 39.1513 18.0191 41.9068 18.0191ZM42.3441 15.0428C41.207 15.0428 40.5801 14.1675 40.5801 12.9712C40.5801 11.3079 41.7464 10.0095 43.1022 10.0095C44.2248 10.0095 44.8225 10.8994 44.8225 12.0958C44.8225 13.7444 43.7146 15.0428 42.3441 15.0428Z"
        fill="white"
      />
    </svg>
  )
}

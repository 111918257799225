export const CashLogo = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8084 10.9871C18.8084 12.5819 18.5584 14.1113 17.5281 15.2389C16.4989 16.3665 15.1031 17 13.6484 17H1.14844V6H12.1484"
        stroke="#1D1E21"
        strokeMiterlimit="1.41421"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.64844 15C11.5814 15 13.1484 13.433 13.1484 11.5C13.1484 9.567 11.5814 8 9.64844 8C7.71544 8 6.14844 9.567 6.14844 11.5C6.14844 13.433 7.71544 15 9.64844 15Z"
        stroke="#1D1E21"
        strokeMiterlimit="1.41421"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1484 9.06825V1H16.0635C16.7889 1 17.4847 1.2899 17.9983 1.80593C18.5118 2.32184 18.8 3.02165 18.8 3.7513V11.7534H18.7988C18.7815 11.0477 18.4958 10.3745 17.9983 9.87406C17.4847 9.35803 16.7889 9.06825 16.0635 9.06825H14.1484Z"
        stroke="#1D1E21"
        strokeMiterlimit="1.41421"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

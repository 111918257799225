export const CheckLogo = () => {
  return (
    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.15095 14L2.00169 18.9132C1.99717 18.9325 2.00181 18.9525 2.01442 18.9687C2.03965 19.0011 2.08924 19.0095 2.12625 18.9884L7.00013 16.2099L11.8737 18.9884C11.8915 18.9985 11.913 19.0022 11.9337 18.9986C11.9779 18.9911 12.0076 18.953 11.9983 18.9131L10.8492 14"
        stroke="#1D1E21"
        strokeLinecap="round"
      />
      <path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        stroke="#1D1E21"
        strokeLinecap="round"
      />
      <path d="M4 7L6.12132 9.12132L9.65685 5.58579" stroke="#1D1E21" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

import { Button, Typography } from "@hero/krypton"
import { Frames } from "frames-react"
import { t } from "i18next"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { HeroLogo } from "../../../assets/logos/Hero"
import { LockLogo } from "../../../assets/logos/Lock"
import { GradientLine } from "../../../ui/GradientLine"
import { CGUBlock } from "./components/CGUblock"
import { ErrorElement } from "./components/ErrorElement"

const CardPaymentContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 26rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
  border: ${({ theme }) => `1px solid ${theme.colors.grey.$200}`};
  margin-bottom: 2rem;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 2rem;
    border-radius: unset;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  < button > div {
    align-items: center;
  }
  > button > div > svg {
    width: 20px;
    height: 20px;
  }
`

const CGU = styled(CGUBlock)`
  margin-bottom: 0.8rem;
`

const StyledTitle = styled(Typography)`
  margin-bottom: 0.3rem;
`

const StyledTypography = styled(Typography)`
  margin-right: 0.2rem;
`

const PoweredBy = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const WhiteLockLogoContainer = styled.div`
  width: 1rem;
  height: 1rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`

const WhiteLockLogo = () => {
  return (
    <WhiteLockLogoContainer>
      <LockLogo color="white" />
    </WhiteLockLogoContainer>
  )
}

const ButtonContainer = styled(Button)`
  & > div {
    display: flex;
    align-items: center;
  }
`

export const CardPayment = ({
  paymentId,
  amount,
  feeConfigurationId,
  isInitiated,
  CheckoutComCardInputs,
}: {
  paymentId: string
  amount: number
  feeConfigurationId: string
  isInitiated: boolean
  CheckoutComCardInputs: React.FC<{
    paymentId: string
    feeConfigurationId: string
    setIsSubmitLoading: (value: boolean) => void
    setError: (value: string) => void
    isInitiated: boolean
  }>
}) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [error, setError] = useState("")

  const [cguAccepted, setCguAccepted] = useState(false)

  const initiatePayment = () => {
    if (!cguAccepted) {
      setError(t("paymentPage.validate.cgu"))
      return
    }
    Frames.submitCard()
    setIsSubmitLoading(true)
    const isCardValid = Frames.isCardValid()
    if (!isCardValid) {
      setError(t("paymentPage.card.notValid"))
      setIsSubmitLoading(false)
      Frames.init()
    }
  }

  const saveNewCard = () => {
    console.log("coucou")
    Frames.submitCard()
    setIsSubmitLoading(true)
    const isCardValid = Frames.isCardValid()
    if (!isCardValid) {
      setError(t("paymentPage.card.notValid"))
      setIsSubmitLoading(false)
      Frames.init()
    }
  }

  useEffect(() => {
    if (cguAccepted) {
      setError("")
    }
  }, [cguAccepted])

  return (
    <CardPaymentContainer>
      <GradientLine />
      <Content>
        <StyledTitle $variant="body-3-semibold">{t("paymentPage.cardInput.title")}</StyledTitle>
        <CheckoutComCardInputs
          paymentId={paymentId}
          feeConfigurationId={feeConfigurationId}
          setIsSubmitLoading={setIsSubmitLoading}
          setError={setError}
          isInitiated={isInitiated}
        />
        {!isInitiated && (
          <CGU
            dataTestId="card-cgublock-checkbox"
            id="card-cgu"
            paymentId={paymentId}
            toggle={() => setCguAccepted(!cguAccepted)}
          />
        )}
        <ButtonContainer
          leftIcon={WhiteLockLogo}
          size="medium"
          isLoading={isSubmitLoading}
          onClick={isInitiated ? saveNewCard : initiatePayment}
        >
          {isInitiated ? `Enregistrer` : t("paymentPage.button.submit", { amount })}
        </ButtonContainer>
        {error && <ErrorElement message={error} />}
        <PoweredBy>
          <StyledTypography $variant="body-3-regular">{t("paymentPage.poweredBy")}</StyledTypography>
          <HeroLogo />
        </PoweredBy>
      </Content>
    </CardPaymentContainer>
  )
}

import { Typography } from "@hero/krypton"
import { t } from "i18next"
import styled from "styled-components"
import { usePayTranslation } from "../../01_technical/translations"
import { PaymentStatus } from "../../business/enum/PaymentStatus"

const BannerContainer = styled.div<{ color: "primary" | "danger" | "warning" }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, color }) => theme.colors[color].$200};
`

const BannerText = styled(Typography)`
  padding: 1rem;
  margin: auto;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`

const PaymentLateBanner = () => {
  const { t } = usePayTranslation()

  return (
    <BannerContainer color="danger">
      <BannerText>{t("banner.late")}</BannerText>
    </BannerContainer>
  )
}

const PaymentUpToDate = () => (
  <BannerContainer color="primary">
    <BannerText>{t("banner.inProgress")}</BannerText>
  </BannerContainer>
)

const PaymentFinished = () => (
  <BannerContainer color="primary">
    <BannerText>{t("banner.finished")}</BannerText>
  </BannerContainer>
)

const InitiatingPaymentAuthFailed = () => (
  <BannerContainer color="warning">
    <BannerText>{t("banner.authFailed")}</BannerText>
  </BannerContainer>
)

const CardAdded3DSFailed = () => (
  <BannerContainer color="warning">
    <BannerText>{t("banner.cardAdded.failed")}</BannerText>
  </BannerContainer>
)

const CardAdded3DSSuccess = () => (
  <BannerContainer color="primary">
    <BannerText>{t("banner.cardAdded.success")}</BannerText>
  </BannerContainer>
)

export const PaymentInfosBanner: React.FC<{
  status: PaymentStatus | null
  isPaymentInitiating3DSFailed: boolean
  cardAdded3DSFailed: boolean
  cardAdded3DSSuccess: boolean
}> = ({ status, isPaymentInitiating3DSFailed, cardAdded3DSFailed, cardAdded3DSSuccess }) => {
  if (isPaymentInitiating3DSFailed) {
    return <InitiatingPaymentAuthFailed />
  }

  if (cardAdded3DSFailed) {
    return <CardAdded3DSFailed />
  }

  if (cardAdded3DSSuccess) {
    return <CardAdded3DSSuccess />
  }

  if (!status) {
    return null
  }
  if (status === PaymentStatus.IN_PROGRESS) {
    return <PaymentUpToDate />
  }

  if ([PaymentStatus.LATE, PaymentStatus.DEFAULT].includes(status)) {
    return <PaymentLateBanner />
  }

  if (PaymentStatus.FINISHED) {
    return <PaymentFinished />
  }

  return null
}
